<template>
  <div class="page page-box">
    <!-- 主体内容 -->
    <main class="page-main-main" ref="main">
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column label="学员姓名" prop="StudentName" align="center" />
        <el-table-column label="所属班级" prop="ClassName" align="center" />
      </el-table>
    </main>
  </div>
</template>

<script>
import { getNoticeDetail } from "@/service/educational";
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
};
export default {
  name: 'Read',
  components: {
    pagination,
  },
  data() {
    return {
      Id: "",
      loading: false, // 加载状态
      tableList: [],  // 表格数据
      listQuery: deepClone(ListQuery),
      total: 0, // 表格总条数
      studentList: [],
      classList: [],
    }
  },
  created() {
    this.Id = this.$route.query.id;
    this.getNoticeDetail();
  },
  methods: {
    /**
     * 获取详情数据
     */
    async getNoticeDetail() {
      this.loading = true
      let res = await getNoticeDetail({ NoticeId: this.Id, Status: 1 });
      this.tableList = res.map(e => {
        let obj = {};
        obj.StudentName = e.Student.Name;
        obj.ClassName = e.Classes.map(item => item.Name).join('/');
        return obj;
      })
      this.loading = false
    },
  }
}
</script>

<style lang='scss' scoped>
// .el-table--border {
//   margin: 0 20%;
// }
/deep/.el-table__body tr,
.el-table__body td {
  height: 50px !important;
}
</style>
