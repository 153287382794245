<template>
  <div class="page-box page-padding">
    <div class="go-class">
      <el-button @click="()=> $router.go(-1)" class="plain-button">
        <div class="img-center"> <img src="@/assets/return.png" alt="返回" class="add-img"> <span>返回</span></div>
      </el-button>
    </div>
    <div class="class-content" v-loading='loading'>
      <h2>通知详情</h2>
      <div class="content-box">
        <el-row>
          <el-col :span="12">
            <span>创建人：</span><span>{{ tableList.CreateUser }}</span>
          </el-col>
          <el-col :span="12">
            <span>通知创建时间：</span><span>{{ tableList.CreateTime&&moment(tableList.CreateTime*1000).format('YYYY-MM-DD') }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <span>通知范围：</span><span>{{ Array.isArray(tableList.Class)&&tableList.Class.map(item=>item.ClubClassName).join('/') }}</span>
          </el-col>
        </el-row>
        <el-row><span>通知标题：</span><span>{{ tableList.Title }}</span></el-row>
        <el-row><span>通知内容：</span><span>{{ tableList.Content }}</span></el-row>
      </div>
      <el-tabs class="tabs-box" v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane :label="`未读(${tableList.AllRead-tableList.Read})`" name="unread">
          <unread v-if="activeName==='unread'" />
        </el-tab-pane>
        <el-tab-pane :label="`已读(${tableList.Read})`" name="read">
          <read v-if="activeName==='read'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import read from '@/views/educational/notifiesComponent/read.vue';
import unread from '@/views/educational/notifiesComponent/unread.vue';
import { getNoticeList } from "@/service/educational";
import moment from 'moment';


export default {
  name: 'NotifiesDetail',
  components: {
    read,
    unread,
  },

  data() {
    return {
      moment,
      Id: "",
      activeName: 'unread', // tab选中标识
      loading: false,
      tableList: {},
      dataList: [],
    }
  },

  created() {
    this.Id = this.$route.query.id;
    this.getNoticeList();
  },

  methods: {

    /**
      * 获取考勤管理列表数据
      */
    async getNoticeList() {
      this.loading = true;
      let res = await getNoticeList();
      this.dateList = res.DataList;
      this.tableList = this.dateList.find(item => item.Id === this.Id)
      this.loading = false;
    },

    /**
     * 改变tab选中
     */
    handleClickTab() {
    }
  }
}
</script>

<style lang='scss' scoped>
h2 {
  text-align: center;
  background: #ffffff;
  padding-top: 16px;
  // padding-left: 32px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #21293a;
}
.content-box {
  // padding: 0 10% 0 20%;
  padding: 24px;
  font-weight: 400;
  color: #6a757e;
  font-size: 14px;
  .el-row {
    margin: 10px 0;
  }
}
/deep/.el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/.el-tabs__item {
  font-size: 22px;
}

// .class-content {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
// }
// .tabs-box {
//   flex: 1;
//   // min-height: 400px;
//   display: flex;
//   flex-direction: column;
//   /deep/.el-tabs__content {
//     flex: 1;
//     // min-height: 100px;
//     height: 0;
//     .el-tab-pane {
//       min-height: 100%;
//       height: 240px;
//     }
//   }
// }
.class-content {
  // flex: 1;
  display: flex;
  flex-direction: column;
}
.tabs-box {
  // flex: 1;
  // min-height: 400px;
  display: flex;
  flex-direction: column;
  /deep/.el-tabs__content {
    flex: 1;
    // min-height: 400px;
    height: 0;
    .el-tab-pane {
      min-height: 100%;
      // height: 540px;
    }
  }
}
</style>